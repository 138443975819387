import { Injectable } from '@angular/core';
import {Data} from 'core/models/data';

@Injectable({ providedIn: 'root' })
export class DataService {

  getEntryForComponentCode(entries: Data[], componentCode: string): Data {
    return entries.find(entry => entry.component === componentCode) || null;
  }

  excludeEntriesWithComponentCodes(entries: Data[], componentCodes: string[]): Data[] {
    return entries.filter(entry => !componentCodes.includes(entry.component));
  }

}
