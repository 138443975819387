import { Injectable } from '@angular/core';
import {interval, Subject} from 'rxjs';
import {AppStateService} from 'app/shared/services/app-state.service';
import {skip} from 'rxjs/operators';
import {environment} from 'environment';

@Injectable({ providedIn: 'root' })
export class RefreshService {

  lastRefresh: Date;
  refreshData$ = new Subject<void>();

  constructor(private appStateService: AppStateService) {
    this.lastRefresh = new Date();
    this.appStateService.show.pipe(skip(1)).subscribe(() => {
      if ((new Date()).getTime() - this.lastRefresh.getTime() > environment.onShowRefreshTimeout) {
        this.refreshData$.next();
      }
    });

    interval(environment.autoRefreshTimeout).subscribe(() => {
      if (this.appStateService.isVisible) {
        if ((new Date()).getTime() - this.lastRefresh.getTime() > environment.autoRefreshTimeout) {
          this.refreshData$.next();
        }
      }
    });

    this.refreshData$.subscribe(() => this.lastRefresh = new Date());
  }

  refresh() {
    this.refreshData$.next();
  }

}
