export const enum AlertLevel {
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
}

export const ORDERED_ALERT_LEVELS = [
  AlertLevel.INFO,
  AlertLevel.WARNING,
  AlertLevel.DANGER,
]

export interface Alert {
  level: AlertLevel;
  title: string;
  body: string;
}
