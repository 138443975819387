import {Pipe} from '@angular/core';
import {PERIOD_MAP} from 'core/i18n/period-map';
import {ComponentService} from 'shared/services/component.service';
import {PeriodNamePipe} from 'shared/pipes/period-name.pipe';

/*
 * Outputs the name of the period for the given component code
 * Usage:
 *   'no2_1h'|component_period_name
 */
@Pipe({
  name: 'component_period_name',
  pure: true,
})
export class ComponentPeriodNamePipe extends PeriodNamePipe {

  constructor(private componentService: ComponentService) {
    super();
  }

  transform(code: string): string {
    const component = this.componentService.getComponentByCode(code);
    if (component) {
      return super.transform(component.period);
    }

    return code;
  }

}
