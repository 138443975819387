import {Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  circle,
  control,
  divIcon,
  featureGroup,
  LatLngBounds,
  LatLngTuple,
  LayerGroup,
  Map,
  map,
  marker,
  tileLayer
} from 'leaflet';
import {environment} from 'environment';
import {Station} from 'core/models/station';
import {StationService} from 'shared/services/station.service';
import {StationgroupNamePipe} from 'shared/pipes/stationgroup-name.pipe';
import {LqiService} from 'shared/services/lqi.service';
import {DataService} from 'shared/services/data.service';
import {Subscription} from 'rxjs';

interface StationgroupLayer {
  stationgroup: string;
  layer: LayerGroup;
  visible: boolean;
}

@Component({
  selector: 'lmn-stations-map',
  templateUrl: './stations-map.component.html',
  styleUrls: ['./stations-map.component.scss'],
  providers: [StationgroupNamePipe]
})
export class StationsMapComponent implements OnInit, OnDestroy {

  @HostBinding('class.lmn-map') private _defaultCssClasses = true;

  @Input() stations: Station[];

  @ViewChild('popupRenderer') private popupRenderer: ElementRef<HTMLDivElement>;

  map: Map;

  stationgroupLayers: StationgroupLayer[];

  activeStation: Station;

  lqiComponent: string = environment.lqiComponent;

  private lqiForStationSubscriptions: Subscription[] = [];

  constructor(private elementRef: ElementRef,
              private stationService: StationService,
              private stationgroupNamePipe: StationgroupNamePipe,
              private lqiService: LqiService,
              private dataService: DataService) {
  }

  ngOnInit() {
    setTimeout(() => this.initMap(), 0);
  }

  ngOnDestroy() {
    this.lqiForStationSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private initMap() {
    this.stationgroupLayers = this.createStationgroupLayers(this.stations);
    this.createMarkers(this.stations);

    const tileLayers = this.createTileLayers(environment.map.tileLayers);

    const layers = [tileLayers[0].tileLayer].concat(this.stationgroupLayers.map(stationgroupLayer => stationgroupLayer.layer));

    this.map = map(
      this.elementRef.nativeElement,
      Object.assign(
        {},
        environment.map.options,
        {
          layers: layers,
        }
      )
    );

    this.map.fitBounds(this.getMarkerBounds(this.stations));

    if (tileLayers && tileLayers.length > 1) {
      control.layers(tileLayers.reduce((namedLayers, tileLayerConfig) => {
        namedLayers[tileLayerConfig.label] = tileLayerConfig.tileLayer;
        return namedLayers;
      }, {}), null, {position: 'topleft'}).addTo(this.map);
    }
  }

  toggleStationgroupLayer(stationgroupLayer: StationgroupLayer) {
    if (stationgroupLayer.visible) {
      this.map.removeLayer(stationgroupLayer.layer);
      stationgroupLayer.visible = false;
    } else {
      this.map.addLayer(stationgroupLayer.layer);
      stationgroupLayer.visible = true;
    }
  }

  private createTileLayers(tileLayerConfig) {
    return tileLayerConfig.map((tileLayerConfigEntry) => {
      return {
        label: tileLayerConfigEntry.label,
        tileLayer: this.createTileLayer(tileLayerConfigEntry)
      };
    });
  }

  private createTileLayer(config) {
    if (config.type === 'wms') {
      return tileLayer.wms(config.url, config.options);
    } else {
      return tileLayer(config.url, config.options);
    }
  }

  private createStationgroupLayers(stations: Station[]): StationgroupLayer[] {
    return this.stationService.getStationgroupsForStations(stations).map(stationgroup => {
      return {
        stationgroup: stationgroup,
        layer: new LayerGroup(),
        visible: true,
      };
    });
  }

  private createMarkers(stations: Station[]) {
    for (const station of stations) {
      const icon = this.getIcon(station);
      for (const groupCode of station.stationgroups) {
        const markerInstance = marker([station.lat, station.lng], {icon: icon});
        const markerAndRadius = featureGroup([markerInstance]);

        let circleInstance;
        if (station.radius) {
          circleInstance = circle([station.lat, station.lng], {
            className: 'lmn-map__lqi is-lqi-undefined',
            // fillOpacity: 0.6,
            weight: 2,
            radius: station.radius,
          }).addTo(markerAndRadius);
        }

        markerInstance.bindPopup(this.popupRenderer.nativeElement);

        markerAndRadius
          .on({
            popupopen: () => {
              this.activeStation = station;
              this.showMarkerAndRadius(markerInstance, circleInstance, station);
              setTimeout(() => { markerInstance.getPopup().update(); }, 0);
            },
            popupclose: () => {
              this.activeStation = null;
              this.hideMarkerAndRadius(markerInstance, circleInstance);
            },
          })
          .addTo(this.getLayerForStationgroup(groupCode));

        this.lqiForStationSubscriptions.push(this.lqiService.retrieveCurrentLqiForStation(station).subscribe((lqiData) => {
          const currentLqiData = this.dataService.getEntryForComponentCode(lqiData, this.lqiComponent);
          markerInstance.setIcon(this.getIcon(station, currentLqiData ? currentLqiData.value : null));
          if (circleInstance) {
            circleInstance.className = 'lmn-map__lqi is-lqi-' + (currentLqiData ? currentLqiData.value : 'undefined');
          }
        }));
      }
    }
  }

  private getLayerForStationgroup(stationgroupCode: string): LayerGroup {
    return this.stationgroupLayers.find(stationgroupLayer => stationgroupLayer.stationgroup === stationgroupCode).layer;
  }

  private showMarkerAndRadius(markerInstance, circleInstance, station) {
    markerInstance.getElement().classList.add('is-active');
    if (circleInstance) {
      circleInstance.getElement().classList.add('is-highlighted');
    }
  }

  private hideMarkerAndRadius(markerInstance, circleInstance) {
    markerInstance.getElement().classList.remove('is-active');
    if (circleInstance) {
      circleInstance.getElement().classList.remove('is-highlighted');
    }
  }

  private getMarkerBounds(stations: Station[]) {
    return new LatLngBounds(stations.map(station => <LatLngTuple>[station.lat, station.lng]));
  }

  private getIcon(station: Station, lqiValue?: number) {
    const mainGroupCodeLetters = this.groupCodesShortGroupCodes(station.stationgroups.filter(groupCode => groupCode !== 'meteorology'));
    const subGroupCodeLetters = this.groupCodesShortGroupCodes(station.stationgroups.filter(groupCode => groupCode === 'meteorology'));

    const iconClassNames = [];
    if (lqiValue) {
      iconClassNames.push('lmn-map__lqi');
      iconClassNames.push('is-lqi-' + lqiValue);
    }

    const markerHtml = `
        <svg class="lmn-map__markerIcon ${iconClassNames.join(' ')} lmn-icon"><use xlink:href="./styleguide/icons.svg#marker"></use></svg>
        <span class="lmn-map__markerLabel">${mainGroupCodeLetters.join('')}</span>
        <span class="lmn-map__markerLabel lmn-map__markerLabel--secondary">${subGroupCodeLetters.join('')}</span>
    `;

    return divIcon({
      className: 'lmn-map__marker',
      html: markerHtml,
      iconSize: [43, 44],
      iconAnchor: [22, 44],
      popupAnchor: [0, -35]
    });
  }

  private groupCodesShortGroupCodes(groupCodes) {
    return groupCodes
      .sort((groupCodeA, groupCodeB) => groupCodeA.localeCompare(groupCodeB))
      .map(groupCode => this.stationgroupNamePipe.transform(groupCode, true));
  }

}
