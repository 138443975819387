import { Pipe, PipeTransform } from '@angular/core';
import {GeoCoordinates} from 'core/models/geo-coordinates';
import {GeoLocationService} from 'app/shared/services/geo-location.service';

/*
 * Outputs the calculated distance between the two given coordinates in a human readable text
 * Usage:
 *   coordinates1|distance:coordinates2
 */
@Pipe({
  name: 'distance',
  pure: true,
})
export class DistancePipe implements PipeTransform {

  constructor(private geoLocationService: GeoLocationService) {}

  transform(coordinates1: GeoCoordinates, coordinates2: GeoCoordinates): string {
    return this.geoLocationService.getAirLineDistanceText(coordinates1, coordinates2);
  }

}
