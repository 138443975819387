import {Pipe} from '@angular/core';
import {ComponentService} from 'shared/services/component.service';
import {Data} from 'core/models/data';
import {DecimalPipe} from '@angular/common';

/*
 * Outputs the value (e.g. 45) formatted according to the decimal point setting of the given component
 * Usage:
 *   dataEntry|data_value
 *   dataEntry|data_value:emptyValue
 */
@Pipe({
  name: 'data_value',
  pure: true,
})
export class DataValuePipe {

  constructor(
    private componentService: ComponentService,
    private decimalPipe: DecimalPipe) {
  }

  transform(dataEntry: Data, emptyValue?: number): string {

    if (emptyValue !== undefined && dataEntry.value === emptyValue) {
      return '–';
    }

    const component = this.componentService.getComponentByCode(dataEntry.component);
    let digitsInfo: string;
    if (component) {
      digitsInfo = `1.${component.decimalPoints}-${component.decimalPoints}`;
    }

    return this.decimalPipe.transform(dataEntry.value, digitsInfo);
  }

}
