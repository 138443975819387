import {Pipe, PipeTransform} from '@angular/core';
import {I18nSelectPipe} from '@angular/common';
import {PERIOD_MAP} from 'core/i18n/period-map';

/*
 * Outputs the name of the period for the given code
 * Usage:
 *   '24h'|period_name
 */
@Pipe({
  name: 'period_name',
  pure: true,
})
export class PeriodNamePipe implements PipeTransform {

  private i18nSelectPipe: I18nSelectPipe;

  constructor() {
    this.i18nSelectPipe = new I18nSelectPipe();
  }

  transform(code: string): string {
    const name = this.i18nSelectPipe.transform(code, PERIOD_MAP);

    return name || code;
  }

}
