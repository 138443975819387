import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {ErrorPageComponent} from './core/components/error-page/error-page.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: 'component-cores',
    loadChildren: 'app/component-core-details/component-core-details.module#ComponentCoreDetailsModule',
  },
  {
    path: 'legal',
    loadChildren: 'app/legal/legal.module#LegalModule',
  },
  {
    path: 'alerts',
    loadChildren: 'app/alert/alert.module#AlertModule',
  },
  {
    path: 'dashboard',
    loadChildren: 'app/dashboard/dashboard.module#DashboardModule',
  },
  {
    path: 'stations',
    loadChildren: 'app/station/station.module#StationModule',
  },
  {
    path: 'ozone-distribution',
    loadChildren: 'app/ozone-distribution-map/ozone-distribution-map.module#OzoneDistributionMapModule',
  },
  {
    path: 'debug',
    loadChildren: 'app/debug/debug.module#DebugModule',
  },
  { path: 'error', component: ErrorPageComponent },
  { path: '**', component: ErrorPageComponent },
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, enableTracing: false, preloadingStrategy: PreloadAllModules })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
