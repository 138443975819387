import { Injectable } from '@angular/core';
import {environment} from 'environment';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  isFeatureEnabled(feature: string): boolean {
    return !!environment.features[feature];
  }

  isFeatureDisabled(feature: string): boolean {
    return !this.isFeatureEnabled(feature);
  }

  areAllFeaturesEnabled(features: string[]) {
    return !features.find(feature => this.isFeatureDisabled(feature));
  }
}
