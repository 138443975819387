import {Injectable} from '@angular/core';
import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {environment} from 'environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Alert, AlertLevel, ORDERED_ALERT_LEVELS} from '../models/alert';
import {RefreshService} from './refresh.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  alertsLoading$ = new BehaviorSubject<boolean>(true);
  alerts$ = new ReplaySubject<Alert[]>(1);
  alertsCount$ = new BehaviorSubject<number>(0);
  highestLevel$ = new BehaviorSubject<AlertLevel>(AlertLevel.INFO);

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private refreshService: RefreshService) {
    this.alerts$.subscribe(alerts => {
      this.alertsCount$.next(alerts.length);
      this.highestLevel$.next(this.getHighestAlertLevel(alerts));
      this.alertsLoading$.next(false);
    });

    this.refresh();
    this.refreshService.refreshData$.subscribe(() => {
      this.refresh();
    });
  }

  refresh(): void {
    this.alertsLoading$.next(true);
    this.http.get<Alert[]>(
      `${this.apiUrl}/alerts`)
      .subscribe((alerts) => {
        this.alerts$.next(alerts);
      }, (error) => {
        this.alerts$.next(this.getLoadingErrorAlerts(error));
      });
  }

  private getLoadingErrorAlerts(error: HttpErrorResponse): Alert[] {
    return [
      {
        level: AlertLevel.WARNING,
        title: 'Meldungen können im Moment leider nicht abgerufen werden.',
        body: error.message,
      }
    ];
  }

  private getHighestAlertLevel(alerts: Alert[]): AlertLevel {
    return ORDERED_ALERT_LEVELS[alerts.reduce((highestLevel: number, alert: Alert) => {
      return ORDERED_ALERT_LEVELS.indexOf(alert.level) > highestLevel ? ORDERED_ALERT_LEVELS.indexOf(alert.level) : highestLevel;
    }, 0)];
  }

  /**
   * Temporarily update alert meta data for the given aler level.
   * This can be used e.g. when a push notification has been received to indicate an immediate alert status change
   * until the alerts have been refreshed.
   * @param level
   */
  addTemporaryAlert(level: AlertLevel) {
    this.alertsCount$.next(Math.max(this.alertsCount$.value, 1));
    this.highestLevel$.next(level);
  }

}
