import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {
  GeoLocationService, PERMISSION_STATE_GRANTED, PERMISSION_STATE_PROMPT, PERMISSION_STATE_UNDEFINED,
  PermissionState
} from 'shared/services/geo-location.service';
import {Subscription} from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lmn-geo-location-required-message',
  templateUrl: './geo-location-required-message.component.html',
  styleUrls: ['./geo-location-required-message.component.scss']
})
export class GeoLocationRequiredMessageComponent implements OnInit, OnDestroy {

  state: PermissionState;
  private stateSubscription: Subscription;

  PERMISSION_STATE_GRANTED = PERMISSION_STATE_GRANTED;
  PERMISSION_STATE_PROMPT = PERMISSION_STATE_PROMPT;
  PERMISSION_STATE_UNDEFINED = PERMISSION_STATE_UNDEFINED;

  constructor(private geoLocationService: GeoLocationService, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.stateSubscription = this.geoLocationService.permissionState$.subscribe((status) => {
      this.state = status;
      this.cd.markForCheck();
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    this.stateSubscription.unsubscribe();
  }

  requestPermissions() {
    this.geoLocationService.requestPermissions();
  }

}
