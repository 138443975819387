<router-outlet name="popup"></router-outlet>
<div [class.is-loading]="loading" class="layout lmn-scrollable lmn-loadingContainer is-blocking">
  <lmn-core-loading-container-loader></lmn-core-loading-container-loader>
  <header class="layout__header">
    <lmn-header></lmn-header>
  </header>
  <div class="layout__main lmn-scrollable lmn-scrollable--scrollbars" id="mainContent">
    <main class="layout__mainContent">
      <router-outlet></router-outlet>
    </main>
    <footer class="layout__mainFooter">
      <lmn-footer></lmn-footer>
    </footer>
  </div>
</div>
