import {Component, OnInit} from '@angular/core';
import {TitleService} from 'core/services/title.service';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from '@angular/router';
import {PushNotificationService} from 'core/services/push-notification.service';

@Component({
  selector: 'lmn-app',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  loading = true;

  constructor(private titleService: TitleService,
              private pushNotificationService: PushNotificationService,
              private router: Router) {
  }

  ngOnInit() {
    this.titleService.init();
    this.router.events.subscribe((routerEvent: RouterEvent) => {
      this.setLoadingForRouterEvent(routerEvent);
    });
  }


  setLoadingForRouterEvent(routerEvent: RouterEvent): void {
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
    }

    if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
      this.loading = false;
    }
  }
}
