import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Data} from 'core/models/data';
import {DataService} from 'shared/services/data.service';

@Component({
  selector: 'lmn-overall-lqi',
  templateUrl: './overall-lqi.component.html',
  styleUrls: ['./overall-lqi.component.scss']
})
export class OverallLqiComponent implements OnInit {

  @Input()
  set lqi(value: Data[]) {
    if (value) {
      this.overallLqi = this.dataService.getEntryForComponentCode(value, 'lqi');
    } else {
      this.overallLqi = null;
    }
  }

  overallLqi: Data;

  constructor(private dataService: DataService, private elmentRef: ElementRef) { }

  ngOnInit() {

  }

}
