import {baseEnvironment} from './environment.base';

export const environment = {
  ...baseEnvironment,
  environment: 'test',
  production: true,
  apiUrl: 'https://hamburg.luftmessnetz-dev.de/api',
  features: {
    ...baseEnvironment.features,
  }
};
