import {Component, Input, OnInit} from '@angular/core';
import {Data} from 'core/models/data';
import {DataService} from 'shared/services/data.service';

@Component({
  selector: 'lmn-lqi',
  templateUrl: './lqi.component.html',
  styleUrls: ['./lqi.component.scss']
})
export class LqiComponent implements OnInit {

  @Input() linkToCore = true;

  private _lqi: Data[];
  @Input()
  set lqi(value: Data[]) {
    if (value) {
      this.overallLqi = this.dataService.getEntryForComponentCode(value, 'lqi');
      this.lqiComponents = this.dataService.excludeEntriesWithComponentCodes(value, ['lqi']);
    } else {
      this.overallLqi = null;
      this.lqiComponents = [];
    }

    this._lqi = value;
  }
  get lqi(): Data[] {
    return this._lqi;
  }

  lqiComponents: Data[];
  overallLqi: Data;

  constructor(private dataService: DataService) { }

  ngOnInit() {
  }

}
