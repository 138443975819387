import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Station} from 'core/models/station';
import {Data} from 'core/models/data';
import {StationService} from 'shared/services/station.service';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'lmn-latest-station-data-by-period',
  templateUrl: './current-station-data-by-period.component.html',
  styleUrls: ['./current-station-data-by-period.component.scss']
})
export class CurrentStationDataByPeriodComponent implements OnInit, OnDestroy {

  @Input() linkToCore = true;
  @Input() station: Station;
  @Input() period: string;

  public entries$: Observable<Data[]>;
  public updatedAt: string;

  private entriesSubscription: Subscription;

  constructor(private stationService: StationService) { }

  ngOnInit() {
    this.entries$ = this.stationService.retrieveCurrentDataEntry(this.station.code, this.period);
    this.entriesSubscription = this.entries$.subscribe(entries => this.updatedAt = entries.length > 0 ? entries[0].datetime : null);
  }

  ngOnDestroy() {
    this.entriesSubscription.unsubscribe();
  }

}
