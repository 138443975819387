import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {FeatureService} from 'app/feature/services/feature.service';

@Directive({
  selector: '[lmnIfAllFeaturesEnabled]'
})
export class IfAllFeatureEnabledDirective {

  constructor(
    private featureService: FeatureService,
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
  }

  @Input()
  set lmnIfAllFeaturesEnabled(features: string[]) {
    if (this.featureService.areAllFeaturesEnabled(features)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}
