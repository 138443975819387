import {Component, ElementRef, HostBinding, Input} from '@angular/core';
import {ScrollService} from 'core/services/scroll.service';

@Component({
  selector: 'lmn-errors',
  templateUrl: 'errors.component.html',
})
export class ErrorsComponent {

  @HostBinding('class.lmn-inputGroup') @Input() private inputGroup = false;

  @Input() ignoreOnGlobalScrollToError = false;
  @Input() protected scrollIntoViewOnError = false;

  @Input() set errors(value: string | string[]) {
    if (typeof value === 'string') {
      this.parsedErrors = [value];
    } else if (Array.isArray(value)) {
      this.parsedErrors = value;
    } else if (!value) {
      this.parsedErrors = [];
    } else {
      this.parsedErrors = [];
      console.error('ErrorsComponent: unexpected errors value', value);
    }

    if (this.scrollIntoViewOnError) {
      this.scrollIntoView();
    }
  }
  @Input() level = 'error';

  parsedErrors: string[] = [];

  constructor(private scrollService: ScrollService,
              private elementRef: ElementRef) {
  }

  public hasErrors(): boolean {
    return this.parsedErrors.length > 0;
  }

  public scrollIntoView(force = false): void {
    if (this.hasErrors()) {
      if (this.elementRef && this.elementRef.nativeElement) {
        this.scrollService.scrollToElement(this.elementRef.nativeElement);
      }
    }
  }
}
