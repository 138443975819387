import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppStateService} from 'shared/services/app-state.service';
import {Observable} from 'rxjs';
import {RefreshService} from 'core/services/refresh.service';

@Component({
  templateUrl: './error-page.component.html',
})
export class ErrorPageComponent implements OnInit {

  status: string;

  statusToHeadlineMapping = {
    ['0']: 'Es konnten keine Daten abgerufen werden',
    ['404']: 'Seite nicht gefunden',
    ['500']: 'Interner Server Fehler',
    ['401']: 'Bitte loggen Sie sich ein',
    ['403']: 'Sie sind leider nicht berechtigt, die angeforderte Aktion auszuführen.',
  };

  wasOffline!: boolean;
  isOffline$!: Observable<boolean>;
  isOnline$!: Observable<boolean>;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private appState: AppStateService,
              private refreshService: RefreshService) {
  }

  ngOnInit() {
    this.status = this.route.snapshot.params['status'] || '404';
    this.wasOffline = !this.appState.isOnline.getValue();
    this.isOffline$ = this.appState.isOffline;
    this.isOnline$ = this.appState.isOnline;
  }

  refresh() {
    this.refreshService.refresh();
    this.router.navigate(['/']);
  }

}
