import {Pipe, PipeTransform} from '@angular/core';
import {CoreService} from 'shared/services/core.service';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

export const CORE_NAME_FORMAT_FULL = 'full';
export const CORE_NAME_FORMAT_SHORT = 'short';

/*
 * Outputs the name of the core for the given code
 * Usage:
 *   chb|core_name:'short'
 */
@Pipe({
  name: 'core_name',
  pure: true,
})
export class CoreNamePipe implements PipeTransform {

  constructor(private coreService: CoreService) {}

  transform(code: string, format = CORE_NAME_FORMAT_FULL): Observable<string> {
    if (code === 'lqi') {
      return of('LQI');
    }

    return this.coreService
      .retrieveCoreByCode(code)
      .pipe(
        map((core) => {
          if (core) {
            switch (format) {
              case CORE_NAME_FORMAT_FULL:
                return core.name;
              case CORE_NAME_FORMAT_SHORT:
                return core.shortName;
              default:
                throw new Error(`Invalid core name format "${format}`);
            }
          } else {
            return code;
          }
        }),
        catchError(() => code)
      );
  }

}
