import {Pipe} from '@angular/core';
import {ComponentService} from 'shared/services/component.service';

/*
 * Outputs the unit (e.g. µg/m³) associated with the given component code
 * Usage:
 *   'no2_1h'|component_unit
 */
@Pipe({
  name: 'component_unit',
  pure: true,
})
export class ComponentUnitPipe {

  constructor(private componentService: ComponentService) {
  }

  transform(code: string): string {
    const component = this.componentService.getComponentByCode(code);
    if (component) {
      return component.unit;
    }
  }

}
