import {Component, Input} from '@angular/core';
import {ErrorsComponent} from '../errors/errors.component';

@Component({
  selector: 'lmn-form-control-errors',
  templateUrl: 'form-control-errors.component.html',
})
export class FormControlErrorsComponent extends ErrorsComponent {
  @Input() protected = false;
}
