import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {FeatureService} from 'app/feature/services/feature.service';

@Directive({
  selector: '[lmnIfFeatureEnabled]'
})
export class IfFeatureEnabledDirective {

  constructor(
    private featureService: FeatureService,
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
  }

  @Input()
  set lmnIfFeatureEnabled(feature: string) {
    if (this.featureService.isFeatureEnabled(feature)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}
