import {Injectable} from '@angular/core';
import {GeoCoordinates} from 'core/models/geo-coordinates';
import {Subject, ReplaySubject, Observable, BehaviorSubject} from 'rxjs';
import {AppStateService} from 'app/shared/services/app-state.service';
import {combineLatest, distinctUntilChanged, map, startWith, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {FeatureService} from 'app/feature/services/feature.service';

export const PERMISSION_STATE_GRANTED = 'granted';
export const PERMISSION_STATE_PROMPT = 'prompt';
export const PERMISSION_STATE_DENIED = 'denied';
export const PERMISSION_STATE_UNDEFINED = 'undefined';

export type PermissionState = 'granted'|'prompt'|'denied'|'undefined';

@Injectable({ providedIn: 'root' })
export class GeoLocationService {

  currentUserLocation$ = new BehaviorSubject<GeoCoordinates>(null);
  permissionState$ = new ReplaySubject<PermissionState>(1);

  private currentUserLocationUnfiltered$ = new Subject<GeoCoordinates>();
  private watchHandle: any;

  constructor(
    private featureService: FeatureService,
    private appStateService: AppStateService,
    private httpClient: HttpClient) {

    if (this.featureService.isFeatureEnabled('geolocation')) {
      this.currentUserLocationUnfiltered$
        .pipe(
          distinctUntilChanged((location1: GeoCoordinates, location2: GeoCoordinates) => {
            // why do we return true if it has NOT changed?
            // the rx documentation is somewhat misleading. So lets look at the code:
            // https://github.com/ReactiveX/rxjs/blob/5.0.0-beta.12/src/operator/distinctUntilChanged.ts#L81
            // If we return false here, the value will be emitted.
            return this.areSameCoordinates(location1, location2);
          })
        )
        .subscribe(this.currentUserLocation$);

      this.appStateService.show.subscribe(() => {
        this.enableWatchPosition();
      });

      this.appStateService.hide.subscribe(() => {
        this.disableWatchPosition();
      });

      this.enableWatchPosition();

      this.watchPermissionStatus();
    }
  }

  watchPermissionStatus() {
    if (navigator['permissions']) {
      navigator['permissions'].query({name: 'geolocation' }).then((result) => {
        this.permissionState$.next(result.state);
        result.onchange = () => {
          if (result.state === PERMISSION_STATE_PROMPT) {
            this.requestPermissions();
          }
          this.permissionState$.next(result.state);
        };
      });
    } else {
      this.permissionState$.next(PERMISSION_STATE_UNDEFINED);
    }
  }

  requestPermissions() {
    this.disableWatchPosition();
    this.enableWatchPosition();
  }

  retrieveSortedByDistanceToCurrentUserLocation<T extends GeoCoordinates>(objects$: Observable<T[]>): Observable<T[]> {
    return this.currentUserLocation$
      .pipe(
        startWith(null),
        combineLatest(objects$),
        map((combineLocationAndStations) => {
          const [location, stations] = combineLocationAndStations;
          return this.sortByDistance(location, stations);
        })
      );
  }

  sortByDistance<T extends GeoCoordinates>(targetCoordinates: GeoCoordinates, objects: T[]): T[] {
    if (targetCoordinates === null) {
      return objects.slice(0);
    }

    return objects.sort((a, b) => {
      return this.getAirLineDistanceInMeters(targetCoordinates, a) - this.getAirLineDistanceInMeters(targetCoordinates, b);
    });
  }

  getClosest<T extends GeoCoordinates>(targetCoordinates: GeoCoordinates, objects: T[]): T {
    return this.sortByDistance(targetCoordinates, objects)[0];
  }

  private enableWatchPosition() {
    if (navigator.geolocation && !this.watchHandle) {
      this.watchHandle = navigator.geolocation.watchPosition(
        position => this.updatePosition(position),
        error => {
          this.updatePositionByIp();
        },
        {
          timeout: 1000,
          enableHighAccuracy: false,
        }
      );
    }
  }

  private updatePositionByIp() {
    this.httpClient.get<{latitude: number, longitude: number}>('https://freegeoip.net/json/').subscribe(result => {
      this.currentUserLocationUnfiltered$.next({
        lat: result.latitude,
        lng: result.longitude
      });
    });
  }

  private disableWatchPosition() {
    if (this.watchHandle) {
      navigator.geolocation.clearWatch(this.watchHandle);
      this.watchHandle = null;
    }
  }

  private updatePosition(position: any) {
    this.currentUserLocationUnfiltered$.next({
      lat: position.coords.latitude,
      lng: position.coords.longitude
    });
  }

  areSameCoordinates(coordinates1: GeoCoordinates, coordinates2: GeoCoordinates): boolean {
    return this.round(coordinates1.lat, 4) === this.round(coordinates2.lat, 4) && this.round(coordinates1.lng, 4) === this.round(coordinates2.lng, 4);
  }

  getAirLineDistanceInMeters(coordinates1: GeoCoordinates, coordinates2: GeoCoordinates) {
    const p = 0.017453292519943295;    // Math.PI / 180
    const c = Math.cos;
    const a = 0.5 - c((coordinates2.lat - coordinates1.lat) * p) / 2 +
      c(coordinates1.lat * p) * c(coordinates2.lat * p) *
      (1 - c((coordinates2.lng - coordinates1.lng) * p)) / 2;

    return 12742000 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371000 m
  }

  getAirLineDistanceText(coordinates1: GeoCoordinates, coordinates2: GeoCoordinates): string {
    if (!coordinates1 || !coordinates2) {
      return '';
    }

    const airLineDistance = this.round(this.getAirLineDistanceInMeters(coordinates1, coordinates2), -1);
    if (airLineDistance < 1000) {
      return `${airLineDistance} m`;
    } else {
      return this.round(airLineDistance / 1000, 1) + ' km';
    }
  }

  getBackendDistanceText(distanceMeter: number): string {
    if (typeof distanceMeter !== 'undefined' && distanceMeter !== null) {
      if (distanceMeter < 1000) {
        return `${distanceMeter} m`;
      } else {
        return this.round(distanceMeter / 1000, 1) + ' km';
      }
    } else {
      return '';
    }
  }

  private round(value: number, precision): number {
    if (precision < 0) {
      const roundingFactor = Math.pow(10, Math.abs(precision));
      return Math.round(value / roundingFactor) * roundingFactor;
    } else {
      return Number(value.toFixed(precision));
    }
  }

}
