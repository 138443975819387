import {Injectable} from '@angular/core';
import {Component} from 'core/models/component';
import {uniqueFilter} from 'core/utils/unique-filter';
import {environment} from 'environment';
import {HttpClient} from '@angular/common/http';
import {ReplaySubject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ComponentService {

  components$ = new ReplaySubject<Component[]>(1);

  private components: Component[] = [];

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
    this.loadComponents();
    this.components$.subscribe(components => this.components = components);
  }

  private loadComponents(): void {
    this.http.get<Component[]>(
        `${this.apiUrl}/components`,
        {
          params: {
            active: 'true',
            group_code: 'pollution',
          }
        }
      )
      .pipe(map((components: Component[]) => {
        if (environment.components.length > 0) {
          return components.filter(component => environment.components.includes(component.code));
        } else {
          return components;
        }
      }))
      .subscribe(this.components$);
  }

  extractPeriods(components: Component[]): string[] {
    return components.map(component => component.period).filter(uniqueFilter);
  }

  extractCores(components: Component[]): string[] {
    return components.map(component => component.core).filter(uniqueFilter);
  }

  getComponentsByCodes(codes: string[]): Component[] {
    if (!codes) {
      return [];
    }

    return this.components.filter(component => codes.includes(component.code));
  }

  getComponentByCode(code: string): Component {
    return this.getComponentsByCodes([code])[0];
  }

  getComponentByCoreAndPeriod(core: string, period: string): Component {
    return this.components.find(component => component.core === core && component.period === period);
  }

}
