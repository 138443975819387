import {Component, Input, HostBinding, Output, EventEmitter, OnDestroy} from '@angular/core';

@Component({
  selector: 'lmn-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent implements OnDestroy {

  private _open = false;
  @HostBinding('class.is-open') @Input()
  get open(): boolean {
    return this._open;
  }
  set open(value: boolean) {
    this._open = value;
    if (value) {
      this.disableBodyScrolling();
    } else {
      this.enableBodyScrolling();
    }
  }

  @HostBinding('class.lmn-modal') private _defaultClass = true;

  @Input() @HostBinding('class.lmn-modal--fullWidth') private fullWidth = false;
  @Input() @HostBinding('class.lmn-modal--fullHeight') private fullHeight = false;

  @Input() loading = false;
  @Input() noClose = false;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() width: string;
  @Input() height: string;


  @Input() flashHighlightTrigger: any;

  @Output() closed = new EventEmitter<void>();

  close() {
    this.enableBodyScrolling();
    this.closed.emit();
  }

  ngOnDestroy() {
    this.enableBodyScrolling();
  }


  private disableBodyScrolling() {
    document.querySelector('html').classList.add('is-lmn-modal-open');
  }

  private enableBodyScrolling() {
    document.querySelector('html').classList.remove('is-lmn-modal-open');
  }

}
