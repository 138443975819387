import {Component} from '@angular/core';
import {SelectControlComponent} from 'shared/components/select-control/select-control.component';
import {PERIOD_MAP} from 'core/i18n/period-map';

@Component({
  selector: 'lmn-period-select-control',
  templateUrl: '../select-control/select-control.component.html',
})
export class PeriodSelectControlComponent extends SelectControlComponent {

  i18nSelectMap = PERIOD_MAP;

}
