import {
  AfterContentInit,
  ChangeDetectionStrategy, Component, ContentChildren, forwardRef, HostBinding, Input,
  QueryList
} from '@angular/core';
import {AccordionItemComponent} from './AccordionItem/accordion-item.component';

@Component({
  selector: 'lmn-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent implements AfterContentInit {

  @HostBinding('class.lmn-accordion') _defaultClasses = true;

  @Input() closeOthers = true;

  @ContentChildren(forwardRef(() => AccordionItemComponent)) items: QueryList<AccordionItemComponent>;

  ngAfterContentInit() {
    this.setAccordionComponentOnAccordionItems();
    this.items.changes.subscribe(() => this.setAccordionComponentOnAccordionItems());
  }

  setAccordionComponentOnAccordionItems() {
    this.items.forEach(itemComponent => itemComponent.accordion = this);
  }

  closeAll() {
    const closeOthers = this.closeOthers;
    this.closeOthers = false;
    this.items.forEach(item => {
      item.close();
    });
    this.closeOthers = closeOthers;
  }
}
