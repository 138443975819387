import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {filter, map, mergeMap} from 'rxjs/operators';
import {environment} from 'environment';
import {BehaviorSubject} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TitleService {

  title$ = new BehaviorSubject<string>('');

  private titleSuffix: string;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private titleService: Title) {
    this.titleSuffix = environment.titleSuffix;
  }

  init() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }

        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((event) => this.title$.next(event['title']));

    this.title$.subscribe(title => {
      if (title) {
        this.titleService.setTitle(title + this.titleSuffix);
      } else {
        this.titleService.setTitle(this.titleSuffix);
      }
    });
  }

  setTitle(title: string) {
    this.title$.next(title);
  }

}
