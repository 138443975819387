export const PERIOD_MAP = {
  '1h': 'Stundenwerte',
  '8h': '8-Stundenwerte',
  '24h': 'Tageswerte',
  '1m': 'Monatswerte',
  '1y': 'Jahreswerte',
  '8hg': '8-Stundenwerte (gleitend)',
  '24hg': '24-Stundenwerte (gleitend)',
  '1yaot40': 'AOT40 1-Jahres-Wert',
  '5yaot40': 'AOT40 5-Jahres-Wert',
};
