import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {fromEvent} from 'rxjs/observable/fromEvent';

@Injectable({ providedIn: 'root' })
export class AppStateService {

  isVisible = new BehaviorSubject<boolean>(true);
  show: Observable<boolean>;
  hide: Observable<boolean>;
  isOnline = new BehaviorSubject<boolean>(navigator.onLine);
  isOffline = this.isOnline.pipe(map(isOnline => !isOnline));
  online: Observable<boolean>;
  offline: Observable<boolean>;

  constructor() {
    this.setupVisibilityChangeListeners();
    this.setupOnlineStateChangeListeners();
  }

  setupVisibilityChangeListeners() {
    fromEvent(document, 'visibilitychange').subscribe((e: Event) => {
      this.isVisible.next(!document.hidden);
    });
    fromEvent(document, 'pause').subscribe(e => {
      this.isVisible.next(false);
    });
    fromEvent(document, 'resume').subscribe(e => {
      this.isVisible.next(!document.hidden);
    });

    this.show = this.isVisible.pipe(distinctUntilChanged(), filter(isVisible => isVisible));
    this.hide = this.isVisible.pipe(distinctUntilChanged(), filter(isVisible => !isVisible));
  }

  setupOnlineStateChangeListeners() {
    fromEvent(window, 'online').subscribe((e: Event) => {
      this.isOnline.next(true);
    });

    fromEvent(window, 'offline').subscribe((e: Event) => {
      this.isOnline.next(false);
    });

    this.show.subscribe(() => {
      this.isOnline.next(navigator.onLine);
    });

    this.online = this.isOnline.pipe(distinctUntilChanged(), filter(isOnline => isOnline));
    this.offline = this.isOnline.pipe(distinctUntilChanged(), filter(isOnline => !isOnline));
  }

}
