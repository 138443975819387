import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'lmn-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  private enableDebugModeClickCounter = 0;
  private debugModeTimeout: number;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  enableDebugMode() {
    if (this.debugModeTimeout) {
      window.clearTimeout(this.debugModeTimeout);
      this.debugModeTimeout = null;
    }
    this.enableDebugModeClickCounter++;
    if (this.enableDebugModeClickCounter >= 7) {
      this.router.navigate(['/debug']);
    } else {
      this.debugModeTimeout = window.setTimeout(() => this.enableDebugModeClickCounter = 0, 1000);
    }
  }

}
