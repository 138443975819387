import {Input, OnInit, OnDestroy, ElementRef, Directive, HostBinding} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

@Directive({
  selector: 'svg[icon]', // tslint:disable-line:directive-selector
})
export class SvgIconDirective implements OnInit, OnDestroy {

  @HostBinding('class.lmn-icon') private _classIcon = true;
  @Input() @HostBinding('class.lmn-icon--primary') private primary = false;
  @Input() @HostBinding('class.lmn-icon--hoverPrimary') private hoverPrimary = false;
  @Input() @HostBinding('class.lmn-icon--tiny') private tiny = false;
  @Input() @HostBinding('class.lmn-icon--small') private small = false;
  @Input() @HostBinding('class.lmn-icon--big') private big = false;
  @Input() @HostBinding('class.lmn-icon--extraBig') private extraBig = false;
  @Input() @HostBinding('class.lmn-icon--light') private light = false;
  @Input() @HostBinding('class.lmn-icon--inverted') private inverted = false;
  @Input() @HostBinding('class.lmn-icon--circle') private circle = false;

  private _icon: string;
  @Input()
  get icon(): string {
    return this._icon;
  }

  set icon(value: string) {
    this._icon = value;
    this.initSubscriptions();
    this.setSvgIconContent();
  }

  private fixedHref: string;

  private routerSubscription: Subscription;

  constructor(private elementRef: ElementRef,
              private router: Router) {}

  ngOnInit(): void {
    this.setSvgIconContent();
  }

  ngOnDestroy(): void {
    this.cancelSubscriptions();
  }

  setSvgIconContent() {
    const fixedHref = window.location.href.replace(window.location.hash, '') + './styleguide/icons.svg#' + this.icon;
    if (this.fixedHref !== fixedHref) {
      this.fixedHref = fixedHref;
      let useTag = this.elementRef.nativeElement.querySelector('use');
      if (!useTag) {
        useTag = document.createElementNS('http://www.w3.org/2000/svg', 'use');
        this.elementRef.nativeElement.appendChild(useTag);
      }

      useTag.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', fixedHref);
    }
  }


  private initSubscriptions() {
    this.cancelSubscriptions();
    this.routerSubscription = this.router.events.subscribe(() => this.setSvgIconContent());
  }

  private cancelSubscriptions() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
