import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Station} from 'core/models/station';
import {StationFavouriteService} from 'shared/services/station-favourite.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'lmn-station-favourite-toggle',
  templateUrl: './station-favourite-toggle.component.html',
  styleUrls: ['./station-favourite-toggle.component.scss']
})
export class StationFavouriteToggleComponent implements OnInit, OnDestroy {

  private _station: Station;
  @Input()
  set station(value: Station) {
    this._station = value;
    if (value) {
      this.isFavourite = this.favouriteService.isFavourite(this.station.code);
    } else {
      this.isFavourite = false;
    }
  }
  get station(): Station {
    return this._station;
  }

  @Input() showLabel = false;

  isFavourite: boolean;
  favouritesUpdatedSubscription: Subscription;

  constructor(public favouriteService: StationFavouriteService) { }

  ngOnInit() {
    this.favouritesUpdatedSubscription = this.favouriteService.favourites$.subscribe(() => {
      this.isFavourite = this.favouriteService.isFavourite(this.station.code);
    });
  }

  ngOnDestroy() {
    this.favouritesUpdatedSubscription.unsubscribe();
  }

  onClick(event: PointerEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.toggle();
  }

  toggle() {
    this.isFavourite = this.favouriteService.toggleFavourite(this.station.code);
  }


}
