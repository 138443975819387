import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Station} from 'core/models/station';
import {combineLatest, map} from 'rxjs/operators';

const cacheKey = 'lmn_station_favourites';

@Injectable({ providedIn: 'root' })
export class StationFavouriteService {

  favourites$ = new BehaviorSubject<string[]>([]);

  constructor() {
    this.loadFromCache().subscribe(favourites => this.favourites$.next(favourites));
  }

  retrieveFavouriteStations(stations$: Observable<Station[]>): Observable<Station[]> {
    return stations$
      .pipe(
        combineLatest(this.favourites$),
        map((stationsAndFavourites) => {
            const stations = stationsAndFavourites[0];

            return stations.filter(station => this.isFavourite(station.code));
        })
      );
  }

  isFavourite(courseId: string): boolean {
    return -1 !== this.getFavouriteIndex(courseId);
  }

  toggleFavourite(courseId: string): boolean {
    const index = this.getFavouriteIndex(courseId);
    const favourites = this.favourites$.value.slice(0);
    const isFavouriteAfterToggle = index === -1;
    if (isFavouriteAfterToggle) {
      favourites.push(courseId);
    } else {
      favourites.splice(index, 1);
    }
    this.favourites$.next(favourites);
    this.storeInCache(favourites);

    return isFavouriteAfterToggle;
  }

  private getFavouriteIndex(courseId): number|undefined {
    return this.favourites$.value.findIndex(favouriteCourseId => favouriteCourseId === courseId);
  }

  private loadFromCache(): Observable<string[]> {
    if (window.localStorage.getItem(cacheKey)) {
      return of(JSON.parse(window.localStorage.getItem(cacheKey)));
    } else {
      return of([]);
    }
  }

  private storeInCache(favourites: string[]) {
    window.localStorage.setItem(cacheKey, JSON.stringify(favourites));
  }
}
