import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environment';
import {Data, DataForStation} from 'core/models/data';
import {Station} from 'core/models/station';
import {Observable, ReplaySubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {RefreshService} from 'app/core/services/refresh.service';

@Injectable({ providedIn: 'root' })
export class LqiService {

  lqis$ = new ReplaySubject<DataForStation[]>(1);

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private refreshService: RefreshService) {
    this.refresh();
    this.refreshService.refreshData$.subscribe(() => {
      this.refresh();
    });
  }

  refresh(): void {
    this.http.get<DataForStation[]>(
      `${this.apiUrl}/lqis/data`
    ).subscribe(this.lqis$);
  }

  retrieveCurrentLqiForStation(station: Station): Observable<Data[]> {
    return this.lqis$.pipe(
      map(dataForStations => {
        return dataForStations.find(dataForStation => {
          return dataForStation.station === station.code;
        });
      }),
      map(dataForStation => {
        if ( dataForStation
          && dataForStation.data
        ) {
          return dataForStation.data;
        } else {
          return null;
        }
      })
    );
  }

  extractCurrentLqiForStationCode(lqis: DataForStation[], stationCode: string): Data[] {
    const lqiForStation = lqis
      .find(dataForStation => {
        return dataForStation.station === stationCode;
      });

    return lqiForStation ? lqiForStation.data : [];
  }

}
