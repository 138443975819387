import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from 'app/app.module';
import {environment} from 'environment';

if (environment.production) {
  enableProdMode();
}

if (window['cordova']) {
  document.addEventListener('deviceready', bootstrap, false);
} else {
  bootstrap();
}

function bootstrap () {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));

}
