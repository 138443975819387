import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnDestroy,
  OnInit
} from '@angular/core';
import {Station} from 'core/models/station';
import {LqiService} from 'shared/services/lqi.service';
import {Observable, Subscription} from 'rxjs';
import {Data} from 'core/models/data';
import {GeoCoordinates} from 'core/models/geo-coordinates';
import {GeoLocationService} from 'shared/services/geo-location.service';
import {environment} from 'environment';

@Component({
  selector: 'lmn-station-list-item',
  templateUrl: './station-list-item.component.html',
  styleUrls: ['./station-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StationListItemComponent implements OnInit, OnDestroy {

  @HostBinding('class.lmn-region')
  @HostBinding('class.lmn-region--shineThroughLight')
  @HostBinding('class.lmn-region--depth')
  private _defaultCssClasses = true;

  private _station: Station;
  @Input()
  set station(value: Station) {
    this.subscribeLqi(value);
    this._station = value;
  }

  get station(): Station {
    return this._station;
  }

  lqi: Data[];
  lqiSubscription: Subscription;

  currentUserLocation: GeoCoordinates;
  currentUserLocationSubscription: Subscription;

  clientHasMultipleStationGroups = false;

  constructor(private lqiService: LqiService,
              private geoLocationService: GeoLocationService,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.currentUserLocationSubscription = this.geoLocationService.currentUserLocation$.subscribe((location) => {
      this.currentUserLocation = location;
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    this.unsubscribeLqi();
    this.currentUserLocationSubscription.unsubscribe();
    this.clientHasMultipleStationGroups = environment.stationGroups.length > 1;
  }

  private subscribeLqi(station: Station) {
    this.unsubscribeLqi();
    this.lqiSubscription = this.lqiService.retrieveCurrentLqiForStation(station).subscribe((lqi) => {
      this.lqi = lqi;
      this.cd.detectChanges();
    });
  }

  private unsubscribeLqi() {
    if (this.lqiSubscription) {
      this.lqiSubscription.unsubscribe();
    }
  }
}
