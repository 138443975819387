import {Injectable, NgZone} from '@angular/core';
import {RefreshService} from 'core/services/refresh.service';
import {Router} from '@angular/router';
import {environment} from 'environment';
import {AlertService} from 'core/services/alert.service';
import {Subject} from 'rxjs';
import NotificationEventResponse = PhonegapPluginPush.NotificationEventResponse;

export const TOPIC_ALERT = 'alert';

const STORAGE_KEY_PREFIX = 'lmn_push_';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  notification$ = new Subject<NotificationEventResponse>();
  notifications: NotificationEventResponse[] = [];

  private topicPrefix = `${environment.environment}_${environment.clientCode}_`;

  private pushNotification: PhonegapPluginPush.PushNotification;

  constructor(private refreshService: RefreshService,
              private alertService: AlertService,
              private router: Router,
              ngZone: NgZone) {

    if (window.PushNotification) {
      this.pushNotification = window.PushNotification.init({
        android: {
          senderID: 'XXXXXXXX',
          clearNotifications: false,
          sound: true,
          vibrate: true,
          // clearBadge: true,
          // forceShow: true,
        },
        ios: {
          sound: true,
          alert: true,
          badge: true,
          clearBadge: true,
          fcmSandbox: environment.environment !== 'prod',
        }
      });

      ngZone.runOutsideAngular(() => {
        this.pushNotification.on('error', function (e) {
          console.error(`Push Notification error: ${e.message}`);
        });

        this.pushNotification.on('notification', (response) => {
          this.notifications.push(response);
          this.notification$.next(response);

          console.log('PUSH RECEIVED', response);
          const topic: string = response.additionalData.topic;

          document.addEventListener('deviceready', () => {
            ngZone.run(() => {
              if (!response.additionalData.coldstart) {
                this.refreshService.refresh();
              }

              if (this.hasSubscribed(topic)) {
                if (response.additionalData.type === TOPIC_ALERT) {
                  this.onAlert(response);
                }
              }
            });
          });
        });
      });
    }

    this.autoSubscribe();
  }

  private onAlert(message: PhonegapPluginPush.NotificationEventResponse) {
    if (!message.additionalData.coldstart && message.additionalData.level) {
      this.alertService.addTemporaryAlert(message.additionalData.level);
    }

    if (!message.additionalData.foreground) {
      this.router.navigate(['/alerts']);
    }
  }

  /**
   * Initially subscribe to all topics;
   * Then re-subscribe to each not explicitly unsubscribed topic
   * because sometimes the FCM subscription gets lost and the topics array in the init method
   * does not seem to work either
   */
  private autoSubscribe() {
    if (environment.cores.length > 0) {
      environment.cores.forEach(core => {
        if (this.hasSubscribed(`${TOPIC_ALERT}_${core}`)) {
          this.subscribe(`${TOPIC_ALERT}_${core}`);
        }
      });
    } else if (this.hasSubscribed(TOPIC_ALERT)) {
      this.subscribe(TOPIC_ALERT);
    }
  }

  subscribe(topic: string) {
    this.setTopicSubscriptionStatus(topic, true);
    if (this.pushNotification) {
      this.pushNotification.subscribe(
        `${this.topicPrefix}${topic}`,
        () => {},
        () => {
          this.setTopicSubscriptionStatus(topic, false);
        });
    }
  }

  unsubscribe(topic: string) {
    if (!this.hasSubscribed(topic)) {
      return;
    }

    this.setTopicSubscriptionStatus(topic, false);
    if (this.pushNotification) {
      this.pushNotification.unsubscribe(
        `${this.topicPrefix}${topic}`,
        () => {},
        () => {
          this.setTopicSubscriptionStatus(topic, true);
        }
      );
    }
  }

  hasSubscribed(topic: string): boolean {
    // treat null (nothing specified) as subscribed (default = subscribed)
    return this.getTopicSubscriptionStatus(topic) !== false;
  }

  private getTopicSubscriptionStatus(topic: string): boolean | null {
    const item = window.localStorage.getItem(`${STORAGE_KEY_PREFIX}${this.topicPrefix}${topic}`);
    switch (item) {
      case '1':
        return true;
      case '0':
        return false;
      case null:
        return null;
      default:
        throw new Error(`Invalid susbcription status: ${item}`);
    }
  }

  private setTopicSubscriptionStatus(topic: string, subscribed: boolean): void {
    window.localStorage.setItem(`${STORAGE_KEY_PREFIX}${this.topicPrefix}${topic}`, subscribed ? '1' : '0');
  }
}
