import {ElementRef, Injectable} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ScrollService {
  scrollTolSelector(selector) {
    if (document.querySelector) {
      this.scrollToElement(document.querySelector(selector));
    }
  }

  scrollToElement(element: Element|ElementRef): void {
    if (element) {
      let domElement: Element;
      if (element instanceof ElementRef) {
        domElement = element.nativeElement;
      } else if (element instanceof Element) {
        domElement = element;
      }

      if (domElement) {
        domElement.scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    }
  }

  scrollToError(containerElement?: Element) {
    // console.log('scroll to error');
    setTimeout(() => {
      const element = containerElement || document;
      const errorElement = element.querySelector('.js-error');
      if (errorElement) {
        const errorContainer = errorElement.closest('.js-errorContainer');
        this.scrollToElement(errorContainer || errorElement);
      }
    }, 0);

  }

  scrollToTop() {
    document.getElementById('mainContent').scrollTop = 0;
  }
}
