import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'nl2br',
  pure: true,
})
export class Nl2brPipe implements PipeTransform {

  transform(value: string): string {
    if (value) {
      return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
    } else {
      return '';
    }
  }
}
