import {Pipe, PipeTransform} from '@angular/core';
import {I18nSelectPipe} from '@angular/common';
import {STATIONGROUP_NAME_MAP} from 'core/i18n/stationgroup-name-map';

/*
 * Outputs the name of the stationgroup for the given code
 * Usage:
 *   'industrial'|stationgroup_name
 */
@Pipe({
  name: 'stationgroup_name',
  pure: true,
})
export class StationgroupNamePipe implements PipeTransform {

  private i18nSelectPipe: I18nSelectPipe;

  constructor() {
    this.i18nSelectPipe = new I18nSelectPipe();
  }

  transform(code: string, outputShortCode = false): string {
    const name = this.i18nSelectPipe.transform(code, STATIONGROUP_NAME_MAP) || code;

    return outputShortCode ? name.slice(0, 1).toUpperCase() : name;
  }

}
