export const LQI_GRADE_MAP = {
  '1': 'sehr gut',
  '2': 'gut',
  '3': 'befriedigend',
  '4': 'ausreichend',
  '5': 'mangelhaft',
  '6': 'ungenügend',
  '-1': 'Störung',
  'none': '',
};
