import {Component, Input, OnInit} from '@angular/core';
import {ExceedWithData} from 'core/models/exceed-with-data';

@Component({
  selector: 'lmn-exceed-gauge',
  templateUrl: './exceed-gauge.component.html',
  styleUrls: ['./exceed-gauge.component.scss']
})
export class ExceedGaugeComponent implements OnInit {

  @Input() exceedWithData: ExceedWithData;

  color: string;

  constructor() { }

  ngOnInit() {
    const maxCount = this.exceedWithData.exceed.maxCount;
    const currentCount = this.exceedWithData.data.value;
    if (maxCount === 0) {
      if (currentCount > 0) {
        this.color = 'lightred';
      } else {
        this.color = 'lightgreen';
      }
    } else {
      const factor = currentCount / maxCount;
      if (factor < 0.33) {
        this.color = 'lightgreen';
      } else if (factor < 0.66) {
        this.color = 'lightyellow';
      } else if (factor < 1) {
        this.color = 'lightorange';
      } else {
        this.color = 'lightred';
      }
    }
  }

}
