import {NgModule} from '@angular/core';
import {ErrorPageComponent} from './components/error-page/error-page.component';
import {HeaderComponent} from './components/header/header.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {CoreLoadingContainerLoaderComponent} from 'core/components/core-loading-container-loader/core-loading-container-loader.component';
import {FooterComponent} from './components/footer/footer.component';
import {FeatureModule} from 'app/feature/feature.module';
import {SvgIconModule} from 'app/svg-icon/svg-icon.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FeatureModule,
    SvgIconModule,
  ],
  declarations: [
    ErrorPageComponent,
    HeaderComponent,
    CoreLoadingContainerLoaderComponent,
    FooterComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    CoreLoadingContainerLoaderComponent,
  ],
  providers: [
  ]
})
export class CoreModule {
}
