import {Pipe, PipeTransform} from '@angular/core';
import {I18nSelectPipe} from '@angular/common';
import {LQI_GRADE_MAP} from 'core/i18n/lqi-grade-map';

/*
 * Outputs the text/name for the given grade (e.g. 1 => sehr gut, 2 => gut)
 * Usage:
 *   2|period_name
 */
@Pipe({
  name: 'grade_text',
  pure: true,
})
export class GradeTextPipe implements PipeTransform {

  private i18nSelectPipe: I18nSelectPipe;

  constructor() {
    this.i18nSelectPipe = new I18nSelectPipe();
  }

  transform(grade: string|number): string {
    grade = grade + '';

    const gradeText = this.i18nSelectPipe.transform(grade, LQI_GRADE_MAP);

    return gradeText || grade;
  }

}
