import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';
import {AccordionComponent} from '../accordion.component';

@Component({
  selector: 'lmn-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionItemComponent {


  @HostBinding('class.lmn-accordion__item')
  @HostBinding('class.lmn-collapsible')
  _defaultClasses = true;

  @HostBinding('class.is-open') @Input() isOpen = false;
  @HostBinding('class.lmn-collapsible--large') @Input() large = false;

  @Output() opened = new EventEmitter<void>();
  @Output() closed = new EventEmitter<void>();
  @Input() headline = '';
  @Input() context: string;

  accordion: AccordionComponent;

  constructor(private elmentRef: ElementRef,
              private cd: ChangeDetectorRef) {
  }

  toggleOpen() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  close() {
    this.closeOthersIfNeeded();
    this.isOpen = false;
    this.closed.emit();
    this.cd.markForCheck();
  }

  open() {
    this.closeOthersIfNeeded();
    this.isOpen = true;
    this.opened.emit();
    this.cd.markForCheck();
  }

  closeOthersIfNeeded() {
    if (this.accordion && this.accordion.closeOthers === true) {
      this.accordion.closeAll();
    }
  }

}
