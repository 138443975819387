import {Directive, ElementRef, HostBinding, Input} from '@angular/core';

@Directive({
  selector: '[lmnFlashHighlight]'
})
export class FlashHighlightDirective {

  @HostBinding('class.lmn-animated')
  @HostBinding('class.lmn-animated--fadeIn')
  private _defaultCssClasses = true;

  private _trigger: any;
  @Input()
  get trigger(): any {
    return this._trigger;
  }
  set trigger(value: any) {
    if (value !== this.trigger) {
      this._trigger = value;
      this.highlight();
    }
  }

  constructor(private el: ElementRef) {
  }

  highlight() {
    this.el.nativeElement.classList.add('lmn-animated', 'lmn-animated--fadeIn');
    setTimeout(() => {
      this.el.nativeElement.classList.remove('lmn-animated--fadeIn');
    }, 500);
  }

}
