import {Component, Input, HostBinding} from '@angular/core';

@Component({
  selector: 'lmn-loading-container-loader',
  templateUrl: './loading-container-loader.component.html',
})
export class LoadingContainerLoaderComponent {
  @HostBinding('class.lmn-loadingContainer__loader') _defaultClass = true;
  @Input() message: string;
}
