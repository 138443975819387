import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable, ReplaySubject} from 'rxjs';
import {environment} from 'environment';
import {Core} from 'core/models/core';

@Injectable({ providedIn: 'root' })
export class CoreService {

  cores$ = new ReplaySubject<Core[]>(1);

  private cores: Core[] = [];

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
    this.loadCores();
    this.cores$.subscribe(cores => this.cores = cores);
  }

  private loadCores(): void {
    this.http.get<Core[]>(
        `${this.apiUrl}/cores`,
        {
          params: {
            active: 'true',
            group_code: 'pollution',
          }
        }
      )
      .pipe(map((cores: Core[]) => {
        if (environment.cores.length > 0) {
          return cores.filter(core => environment.cores.includes(core.code));
        } else {
          return cores;
        }
      }))
      .subscribe(this.cores$);
  }

  retrieveCoreByCode(code: string): Observable<Core> {
    return this.cores$
      .pipe(
        map(cores => cores.find(core => core.code === code) || null)
      );
  }

  getPreviousCore(currentCore: Core): Core {
    const currentIndex = this.getIndexForCode(currentCore.code);
    let previousIndex: number;
    if (currentIndex > 0) {
      previousIndex = currentIndex - 1;
    } else {
      previousIndex = this.cores.length - 1;
    }

    return this.cores[previousIndex];
  }

  getNextCore(currentCore: Core): Core {
    const currentIndex = this.getIndexForCode(currentCore.code);
    let nextIndex: number;
    if (currentIndex < this.cores.length - 1) {
      nextIndex = currentIndex + 1;
    } else {
      nextIndex = 0;
    }

    return this.cores[nextIndex];
  }

  private getIndexForCode(code: string): number {
    return this.cores.findIndex(core => core.code === code);
  }

}
