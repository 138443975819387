import {Injectable} from '@angular/core';
import {environment} from 'environment';
import {Station} from 'core/models/station';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, pipe, ReplaySubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Data} from 'core/models/data';

@Injectable({ providedIn: 'root' })
export class StationService {

  stations$ = new ReplaySubject<Station[]>(1);

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
    this.loadStations();
  }

  retrieveStationByCode(code: string): Observable<Station> {
    return this.stations$
      .pipe(
        map(stations => stations.find(station => station.code === code) || null)
      );
  }

  private loadStations(): void {

    let params = new HttpParams().append('active', '1');

    environment.stationGroups.forEach(stationGroupCode => {
      params = params.append('stationgroup_codes[]', stationGroupCode);
    });

    this.http.get<Station[]>(
      `${this.apiUrl}/stations`,
      {params}
    ).pipe(map(stations => {
        return stations
          .map(station => {
            return {
              ...station,
              thirdParty: station.thirdParty === undefined ? environment.thirdPartyStations.indexOf(station.code) !== -1 : station.thirdParty
            };
          })
          .sort((stationA, stationB) => {
            // thirst party stations should come last in list
            if (stationA.thirdParty) {
              if (stationB.thirdParty) {
                return 0;
              } else {
                return 1;
              }
            } else if (stationB.thirdParty) {
              return -1;
            }

            return stationA.name.localeCompare(stationB.name);
          });
      }))
      .subscribe(this.stations$);
  }


  retrieveData(stationCode: string, params?: any): Observable<Data[]> {
    params = {
      timespan: environment.defaultTimespan,
      ...params
    };

    return this.http
      .get<Data[]>(
        `${this.apiUrl}/stations/${stationCode}/data`,
        {params}
      )
      .pipe(map((data: Data[]) => {
        if (environment.components.length > 0) {
          return data.filter(entry => environment.components.includes(entry.component));
        } else {
          return data;
        }
      }));
  }

  retrieveCurrentDataEntry(stationCode: string, period: string): Observable<Data[]> {
    return this
      .retrieveData(stationCode, {
        period,
        timespan: this.getTimespanForPeriod(period),
      })
      .pipe(map((entries: Data[]) => {
        if (entries.length > 0) {
          const latestDatetime = entries[0].datetime;
          return entries.filter(entry => entry.datetime === latestDatetime);
        } else {
          return [];
        }
      }));
  }

  getStationgroupsForStations(stations: Station[]): string[] {
    return stations.reduce((stationgroups, station) => {
      return stationgroups.concat((station.stationgroups.filter(currentStationgroup => stationgroups.indexOf(currentStationgroup) === -1)));
    }, []);
  }

  private getTimespanForPeriod(period: string): string {
    switch (period) {
      case '1h':
        return 'currentday';
      case '24h':
        return 'lastday';
      case '1m':
        return 'lastmonth';
      default:
        throw new Error(`Invalid period: ${period}`);
    }
  }
}
