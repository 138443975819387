import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {Station} from 'core/models/station';
import {ActivatedRoute} from '@angular/router';

export const VIEW_LIST = 'list';
export const VIEW_MAP = 'map';

@Component({
  selector: 'lmn-stations-list-map',
  templateUrl: './stations-list-map.component.html',
  styleUrls: ['./stations-list-map.component.scss']
})
export class StationsListMapComponent implements OnInit {

  @HostBinding('class.is-list-view') isListView = true;
  @HostBinding('class.is-map-view') isMapView = false;

  @Output() selectedView = new EventEmitter<string>();

  @Input() stations: Station[];

  VIEW_LIST = VIEW_LIST;
  VIEW_MAP = VIEW_MAP;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: {view: string}) => {
      this.selectView(params.view);
    });
  }

  private selectView(view: string) {
    this.isListView = view !== this.VIEW_MAP;
    this.isMapView = view === this.VIEW_MAP;
    this.selectedView.emit(view);
  }


}
