
export const baseEnvironment = {
  clientCode: 'undefined-client-code',
  operator: {
    name: 'XXX Luftmessnetz',
    legalAppName: 'Luftgüte-App für XXX',
    divisions: [
      {
        name: 'Behörde für Gesundheit und Verbraucherschutz',
        url: null
      },
    ],
    streetAddress: 'Street and number',
    zipCode: '12345',
    city: 'City',
    email: 'info@faibl.com',
    phone: null,
    phoneUnformatted: null,
  },
  titleSuffix: ' – Luftmessnetz Online',
  stationWebUrl: 'https://luftmessnetz.de/station/__STATION_CODE__',
  onShowRefreshTimeout: 90000, // milliseconds
  autoRefreshTimeout: 300000, // milliseconds
  features: {
    lqi: true,
    exceeds: true,
    geolocation: true,
    favorites: true,
    ozoneInfo: false,
    debugLink: false,
    clientLogoInHeader: true,
  },
  stationGroups: [
    'background',
    'traffic',
    'ozone',
    'industrial',
    'suburb'
  ],
  cores: [], // only display these cores - empty list => display all available cores
  components: [], // only display these components - empty list => display all available components
  thirdPartyStations: [], // are treated as less important
  defaultTimespan: 'all', // can be used to restrict data to e.g. 'currentday' or 'currentmonth'
  lqiComponent: 'lqi',
  map: {
    options: {
      minZoom: 10,
    },
    tileLayers: [
      {
        label: 'OSM standard',
        type: 'tile',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        options: {
          attribution: 'Map data &copy; <a target="_blank" href="http://www.osm.org">OpenStreetMap&nbsp;</a>',
          format: 'image/gif',
          transparent: true
        }
      },
    ]
  },
  theme: {
    colorBrandPrimary: 'hsla(186, 71%, 34%, 1)',
    colorBrandPrimaryInverted: '#fff',
  },
  text: {
    stationListHeadline: null,
    stationListMenu: null,
  },
};
