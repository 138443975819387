import {Component, Input, OnInit} from '@angular/core';
import {Data} from 'core/models/data';

@Component({
  selector: 'lmn-data-entry',
  templateUrl: './data-entry.component.html',
  styleUrls: ['./data-entry.component.scss']
})
export class DataEntryComponent implements OnInit {

  @Input() data: Data;

  @Input() linkToCore = true;

  @Input() emptyValue: number;

  constructor() { }

  ngOnInit() {
  }

  onCoreNameClick(event: PointerEvent) {
    if (this.linkToCore) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

}
