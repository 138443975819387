import {Component, OnInit} from '@angular/core';
import {SelectControlComponent} from 'shared/components/select-control/select-control.component';
import {CoreService} from 'shared/services/core.service';
import {take} from 'rxjs/operators';
import {Core} from 'core/models/core';

@Component({
  selector: 'lmn-core-select-control',
  templateUrl: '../select-control/select-control.component.html',
})
export class CoreSelectControlComponent extends SelectControlComponent implements OnInit {

  constructor(private coreService: CoreService) {
    super();
  }

  ngOnInit() {
    this.coreService.cores$.pipe(take(1)).subscribe(cores => {
      this.i18nSelectMap = cores.reduce((reduced, core: Core) => {
        reduced[core.code] = core.shortName + ' – ' + core.name;
        return reduced;
      }, {});
    });
    super.ngOnInit();
  }

}
