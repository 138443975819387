import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IfFeatureEnabledDirective} from 'app/feature/directives/if-feature-enabled.directive';
import {IfFeatureDisabledDirective} from 'app/feature/directives/if-feature-disabled.directive';
import {IfAllFeatureEnabledDirective} from 'app/feature/directives/if-all-features-enabled.directive';

@NgModule({
  imports: [
  ],
  declarations: [
    IfFeatureEnabledDirective,
    IfFeatureDisabledDirective,
    IfAllFeatureEnabledDirective,
  ],
  exports: [
    IfFeatureEnabledDirective,
    IfFeatureDisabledDirective,
    IfAllFeatureEnabledDirective,
  ],
})
export class FeatureModule {
}
