<div class="lmn-alert lmn-alert--error">
  <h2 class="lmn-headline lmn-headline--h4 lmn-headline--text lmn-headline--firstInText">Leider ist folgender Fehler aufgetreten:</h2>
  <h2 class="lmn-headline lmn-headline--h3 lmn-headline--text">{{status|i18nSelect: statusToHeadlineMapping}}</h2>

  <div *ngIf="wasOffline">
    <ng-container *ngIf="isOffline$|async">
      Bitte versuchen Sie es erneut, wenn Ihr Gerät wieder mit dem Internet verbunden ist.
    </ng-container>
    <ng-container *ngIf="isOnline$|async">
      <p class="lmn-paragraph">Ihr Gerät ist jetzt wieder mit dem Internet verbunden, bitte versuchen Sie es erneut.</p>
      <button class="lmn-button lmn-button--primary" (click)="refresh()">Daten Aktualisieren</button>
    </ng-container>

  </div>
</div>
