<a *lmnIfFeatureEnabled="'clientLogoInHeader'" routerLink="/" class="logoArea">
  <img src="./assets/client/logo_header.svg" class="logoImage" alt="Hamburg">
</a>

<div class="titleArea">
  <h1 class="lmn-headline lmn-headline--h3" [innerHTML]="title$|async"></h1>
</div>

<a routerLink="/alerts" class="notification notification--level-{{ alertLevel$|async }}">
  <svg icon="bell" [hoverPrimary]="false" [inverted]="!!(alertsCount$|async)" [light]="!(alertsCount$|async)" class="notification__icon"></svg>
  <span class="notification__badge lmn-badge lmn-badge--{{ alertLevel$|async }}">
    {{ alertsCount$|async }}
  </span>
</a>

<div class="menuToggleArea">
  <div class="mobileMenuToggle lmn-menuToggle lmn-clickable lmn-clickable--touchy" [class.is-open]="isMenuOpen"
       (click)="isMenuOpen = !isMenuOpen">
    <span class="lmn-menuToggle__line"></span>
    <span class="lmn-menuToggle__line"></span>
    <span class="lmn-menuToggle__line"></span>
  </div>
</div>


<div class="mobileMenu" [class.is-open]="isMenuOpen">
  <a *lmnIfAllFeaturesEnabled="['geolocation', 'favorites']" routerLink="/dashboard" routerLinkActive="is-active"
     class="mobileMenu__item">Start</a>
  <a routerLink="/stations" routerLinkActive="is-active" [routerLinkActiveOptions]="{exact: true}"
     class="mobileMenu__item">{{ stationListLabelOverride||'Messstationen' }}</a>
  <a *lmnIfFeatureEnabled="'favorites'" routerLink="/stations/watchlist" routerLinkActive="is-active"
     class="mobileMenu__item">Merkliste</a>
  <a routerLink="/ozone-distribution" routerLinkActive="is-active" [routerLinkActiveOptions]="{exact: true}"
     class="mobileMenu__item">Ozon-Verteilung</a>
  <span *lmnIfFeatureEnabled="'refreshLink'" (click)="refresh()" class="mobileMenu__item">Daten aktualisieren</span>
  <a *lmnIfFeatureEnabled="'debugLink'" routerLink="/debug" routerLinkActive="is-active" [routerLinkActiveOptions]="{exact: true}"
     class="mobileMenu__item">Debug</a>
  <a *lmnIfFeatureEnabled="'ozoneInfo'" routerLink="/component-cores/ozone-info" routerLinkActive="is-active" [routerLinkActiveOptions]="{exact: true}"
     class="mobileMenu__item">Sommersmog</a>
  <a routerLink="/legal/privacy" routerLinkActive="is-active" class="mobileMenu__item">Datenschutz</a>
  <a routerLink="/legal/imprint" routerLinkActive="is-active" class="mobileMenu__item">Impressum</a>
</div>
