import {Component, Input, OnInit} from '@angular/core';
import {Station} from 'core/models/station';

@Component({
  selector: 'lmn-stations-list',
  templateUrl: './stations-list.component.html',
  styleUrls: ['./stations-list.component.scss']
})
export class StationsListComponent implements OnInit {

  @Input() stations: Station[];

  constructor() { }

  ngOnInit() {
  }

}
