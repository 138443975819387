import {baseEnvironment as parentBaseEnvironment} from '../../src/environment.base';

export const baseEnvironment = {
  ...parentBaseEnvironment,
  clientCode: 'hamburg',
  legalAppName: 'Ozon-Info-App für Hamburg',
  operator: {
    name: 'Behörde für Umwelt und Energie',
    streetAddress: 'Neuenfelder Straße 19',
    zipCode: '21109',
    city: 'Hamburg',
    email: 'info@bue.hamburg.de',
    phone: '040 428 40-0',
    phoneUnformatted: '+4940428400',
    divisions: [
      {
        name: 'Hamburger Luftmessnetz',
      },
      {
        name: 'Behörde für Gesundheit und Verbraucherschutz',
      },
      {
        name: 'Institut für Hygiene und Umwelt',
        url: 'https://www.hamburg.de/bgv/umweltuntersuchungen/'
      },
      {
        name: 'Abt. Luft und Radioaktivität',
        streetAddress: 'Marckmannstraße 129 b Haus 6',
        zipCode: '20539',
        city: 'Hamburg',
        email: 'luftmessnetz@hu.hamburg.de',
        phone: '040 428 45-3650',
        phoneUnformatted: '+4940428453650',
      },
    ],
  },
  stationGroups: ['ozone'],
  cores: ['o3'],
  components: ['o3_1h'],
  thirdPartyStations: ['24fl'],
  defaultTimespan: 'currentday',
  lqiComponent: 'o3',
  features: {
    lqi: false,
    exceeds: false,
    favorites: false,
    geolocation: false,
    ozoneInfo: true,
    clientLogoInHeader: false,
  },
  stationWebUrl: null, // 'http://luft.hamburg.de/clp/messstationen-aktuelle-messdaten/clp1/station/__STATION_CODE__',
  map: {
    ...parentBaseEnvironment.map,
    tileLayers: [
      ...parentBaseEnvironment.map.tileLayers,
      {
        label: 'Stadtplan',
        type: 'wms',
        url: 'https://geodienste.hamburg.de/HH_WMS_Geobasisdaten?',
        options: {layers: '1,5,9,13', attribution: '', format: 'image/gif', transparent: true}
      },
      {
        label: 'Luftbilder',
        type: 'wms',
        url: 'https://geodienste.hamburg.de/HH_WMS_DOP20?',
        options: {layers: '1', attribution: '', format: 'image/gif', transparent: true}
      },
    ]
  },
  theme: {
    ...parentBaseEnvironment.theme,
    colorBrandPrimary: 'rgb(0,48,99)',
    colorBrandPrimaryInverted: '#fff',
  },
  text: {
    stationListHeadline: 'Aktuelle Daten<br>Ozon (O₃)',
    stationListMenu: 'Aktuelle Daten Ozon',
  },
};
