import {NgModule, Type} from '@angular/core';
import {CommonModule, DatePipe, DecimalPipe} from '@angular/common';
import {LoadingContainerLoaderComponent} from './components/loading-container-loader/loading-container-loader.component';
import {ModalComponent} from './components/modal/modal.component';
import {RouterModule} from '@angular/router';
import {SelectControlComponent} from './components/select-control/select-control.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ErrorsComponent} from './components/errors/errors.component';
import {FormControlErrorsComponent} from './components/form-control-errors/form-control-errors.component';
import {AccordionComponent} from './components/accordion/accordion.component';
import {AccordionItemComponent} from './components/accordion/AccordionItem/accordion-item.component';
import {Nl2brPipe} from './pipes/nl2br.pipe';
import {FlashHighlightDirective} from './directives/flash-highlight.directive';
import {DistancePipe} from './pipes/distance.pipe';
import {StationListItemComponent} from './components/station-list-item/station-list-item.component';
import {LqiComponent} from './components/lqi/lqi.component';
import {StationFavouriteToggleComponent} from './components/station-favourite-toggle/station-favourite-toggle.component';
import {CoreNamePipe} from './pipes/core-name.pipe';
import {DataEntryComponent} from './components/data-entry/data-entry.component';
import {PeriodNamePipe} from './pipes/period-name.pipe';

import {PeriodSelectControlComponent} from './components/period-select-control/period-select-control.component';
import {CoreSelectControlComponent} from 'shared/components/core-select-control/core-select-control.component';
import {GeoLocationRequiredMessageComponent} from './components/geo-location-required-message/geo-location-required-message.component';
import {GradeTextPipe} from 'shared/pipes/grade-text.pipe';
import {OverallLqiComponent} from './components/overall-lqi/overall-lqi.component';
import {StationgroupNamePipe} from 'shared/pipes/stationgroup-name.pipe';
import {ComponentPeriodNamePipe} from 'shared/pipes/component-period-name.pipe';
import {ExceedGaugeComponent} from './components/exceed-gauge/exceed-gauge.component';
import {StationsMapComponent} from 'shared/components/stations-map/stations-map.component';
import {StationsListComponent} from './components/stations-list/stations-list.component';
import {StationsListMapComponent} from './components/stations-list-map/stations-list-map.component';
import {FeatureModule} from 'app/feature/feature.module';
import {CurrentStationDataByPeriodComponent} from './components/current-station-data-by-period/current-station-data-by-period.component';
import {ComponentUnitPipe} from 'shared/pipes/component-unit.pipe';
import {SvgIconModule} from 'app/svg-icon/svg-icon.module';
import {DataValuePipe} from 'shared/pipes/data-value.pipe';



const SHARED_ANGULAR_MODULES: Type<any>[] = [
  CommonModule,
  RouterModule,
  ReactiveFormsModule,
];

const SHARED_ANGULAR_PIPES: Type<any>[] = [
  DecimalPipe,
  DatePipe,
];

const SHARED_MODULES: Type<any>[] = [
  SvgIconModule,
  FeatureModule,
];

const SHARED_PIPES: Type<any>[] = [
  DistancePipe,
  Nl2brPipe,
  CoreNamePipe,
  PeriodNamePipe,
  ComponentPeriodNamePipe,
  ComponentUnitPipe,
  StationgroupNamePipe,
  GradeTextPipe,
  DataValuePipe,
];

const SHARED_COMPONENTS: Type<any>[] = [
  LoadingContainerLoaderComponent,
  AccordionComponent,
  AccordionItemComponent,
  ModalComponent,
  SelectControlComponent,
  ErrorsComponent,
  FormControlErrorsComponent,
  FlashHighlightDirective,
  LqiComponent,
  StationFavouriteToggleComponent,
  DataEntryComponent,
  PeriodSelectControlComponent,
  CoreSelectControlComponent,
  GeoLocationRequiredMessageComponent,
  OverallLqiComponent,
  ExceedGaugeComponent,
  StationsMapComponent,
  StationListItemComponent,
  StationsListComponent,
  StationsListMapComponent,
  CurrentStationDataByPeriodComponent,
];

@NgModule({
  imports: [
    ...SHARED_ANGULAR_MODULES,
    ...SHARED_MODULES,
  ],
  declarations: [
    ...SHARED_PIPES,
    ...SHARED_COMPONENTS,
  ],
  exports: [
    ...SHARED_ANGULAR_MODULES,
    ...SHARED_ANGULAR_PIPES,
    ...SHARED_PIPES,
    ...SHARED_MODULES,
    ...SHARED_COMPONENTS,
  ],
  providers: [
    ...SHARED_ANGULAR_PIPES,
    ...SHARED_PIPES,
  ]
})
export class SharedModule {
}
