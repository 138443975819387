import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {TitleService} from 'core/services/title.service';
import {ScrollService} from 'core/services/scroll.service';
import {AlertService} from 'core/services/alert.service';
import {AlertLevel} from 'core/models/alert';
import {RefreshService} from 'core/services/refresh.service';
import {environment} from 'environment';

@Component({
  selector: 'lmn-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  title$: Observable<string>;
  alertsCount$: Observable<number>;
  alertLevel$: Observable<AlertLevel>;

  readonly stationListLabelOverride = environment.text.stationListMenu;

  private _isMenuOpen = false;
  get isMenuOpen(): boolean {
    return this._isMenuOpen;
  }
  set isMenuOpen(value: boolean) {
    this._isMenuOpen = value;
    if (value) {
      document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
      document.body.style.overflowY = 'hidden';
    } else {
      document.getElementsByTagName('html')[0].style.overflowY = null;
      document.body.style.overflowY = null;
    }
  }

  constructor(private titleService: TitleService,
              private scrollService: ScrollService,
              private alertService: AlertService,
              private refreshService: RefreshService,
              private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe(() => {
      if (this.isMenuOpen) {
        this.scrollService.scrollToTop();
        this.isMenuOpen = false;
      }
    });

    this.title$ = this.titleService.title$;
    this.alertsCount$ = this.alertService.alertsCount$;
    this.alertLevel$ = this.alertService.highestLevel$;
  }

  refresh() {
    this.refreshService.refresh();
    this.isMenuOpen = false;
  }
}
